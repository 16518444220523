
import XTable from '@/components/x-table/x-table.vue'
import { Component, Vue } from 'vue-property-decorator'
import {
  $xTable,
  XTableProxy,
  XTableStdRequestParams,
  XTableStdResponse
} from '@/components/x-table/table.proxy'
import * as API_Goods from '@/api/goods'
import * as API_Order from '@/api/order'
import { Foundation } from '@/../ui-utils'

@Component({
  name: 'PriceChangeRecord',
  components: {
    XTable
  }
})
export default class PriceChangeRecord extends Vue {
  tableProxy: XTableProxy = null!
  parentLogin: any = 0

  mounted() {
    this.isPermission()
    this.tableProxy = $xTable.create({
      key: 'sn',
      search: {
        formData() {
          return {
            time_range: '',
            status: ''
          }
        }
      },
      onRequest: (
        params: XTableStdRequestParams
      ): Promise<XTableStdResponse> => {
        return new Promise(resolve => {
          if (params.search.time_range) {
            params.search['opt_time_start'] = params.search.time_range[0] / 1000
            params.search['opt_time_end'] =
              params.search.time_range[1] / 1000 + 86399
          } else {
            delete params.search['opt_time_start']
            delete params.search['opt_time_end']
          }
          API_Goods.goodsChangePrice({
            page_no: params.pageIndex,
            page_size: params.pageSize,
            ...params.search
          }).then(res => {
            res.data.forEach(item => {
              item.type = item.type === 0 ? '批量改价' : '单品改价'
              item.status = item.status === 0 ? '进行中' : '已完成'
              item.opt_time = Foundation.unixToDate(item.opt_time, '')
            })
            resolve(res)
          })
        })
      }
    })
    console.log(this.tableProxy)
  }

  isPermission() {
    API_Goods.getLoginType().then(res => {
      this.parentLogin = res.parentLogin
      API_Order.getShopExtAuth().then(res => {
        this.$store.getters.permission_routers.forEach(item => {
          if (item.path === '/combo-card-shop/shop-goods') {
            // 关闭菜单
            item.children.forEach((value, index) => {
              if (value.path === 'strong-push') {
                item.children.splice(index, 1)
              }
            })
            item.children.forEach((value, index) => {
              if (value.path === 'intelligence') {
                item.children.splice(index, 1)
              }
            })
          }
        })
        console.log(
          res.force_exchange_goods_open === 'OPEN' && this.parentLogin === 1
        )
        if (
          res.force_exchange_goods_open === 'OPEN' &&
          this.parentLogin === 1
        ) {
          this.$store.getters.permission_routers.forEach(item => {
            console.log(item.path)
            if (item.path === '/combo-card-shop/shop-goods') {
              // 开启菜单
              item.children.push(
                {
                  path: `strong-push`,
                  component: () =>
                    import(`@/views/combo-card-shop/goods/strongPush.vue`),
                  name: `strongPushComboCard`,
                  meta: { title: `strongPushComboCard` }
                },
                {
                  path: `intelligence`,
                  component: () =>
                    import(`@/views/combo-card-shop/goods/intelligence.vue`),
                  name: `intelligenceComboCard`,
                  meta: { title: `intelligenceComboCard` }
                }
              )
            }
          })
        }
      })
    })
  }
}
